import { call, put, PutEffect, putResolve, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { glideQuery } from 'src/api/query';
import { selectCVCUri } from 'src/reducers/tabs';
import {
  GlideLayoutData,
  GlideLayoutDataPayload,
} from 'src/components/grids/dxgrid-client-view/templates/Layouts/Layouts.model';
import { actionTypes, mutateAsync } from 'redux-query';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { selectClientViewData } from 'src/api/views/client-view';
import { SelectLayoutActions, WebLayoutPermission } from 'src/api/layouts.selector';
import {
  ComponentGridLayout,
  selectViewComponent,
  toggleComponentViewDisplayAction,
  updateComponentViewAction,
} from 'src/reducers/components';
import { DisplayViewGroup, View } from 'src/api/queries/display-view';
import { RootState } from 'src/reducers';
import { GlideSession } from '@virtus/common/auth/reducer';
import { NotificationsAction } from 'src/reducers/notifications';
import differenceBy from 'lodash/differenceBy';
import { PivotGrid } from 'devextreme-react/pivot-grid';
import DataGrid from 'devextreme-react/data-grid';
import { onInspectorRelease } from '../glide-object-handler.saga';

interface SaveLayoutNameLocally {
  clientViewConfiguration: ClientViewConfigurationData;
  layoutUri: string;
}

interface SaveLayoutStateLocally {
  clientViewConfiguration: ClientViewConfigurationData;
  gridState: DataGrid | PivotGrid;
}

// persist layout locally
export const saveLayoutNameLocally = ({ clientViewConfiguration, layoutUri }: SaveLayoutNameLocally): void =>
  localStorage.setItem(getSelectedLayoutKey(clientViewConfiguration), layoutUri);

export const saveLayoutStateLocally = ({ clientViewConfiguration, gridState }: SaveLayoutStateLocally) => {
  return localStorage.setItem(getLastGridStateKey(clientViewConfiguration), JSON.stringify(gridState));
};

// Delete layout from local storage
const deleteLayoutLocally = (clientViewConfiguration: ClientViewConfigurationData): void =>
  localStorage.removeItem(getSelectedLayoutKey(clientViewConfiguration));

type LayoutActions = 'CREATE_LAYOUT' | 'UPDATE_LAYOUT' | 'DELETE_LAYOUT' | 'SHARE_LAYOUT';

export interface SetLayoutActionParams {
  layout: GlideLayoutData;
  clientViewUri: string;
}

export interface InitActionParams {
  cvc: ClientViewConfigurationData;
  layouts: GlideLayoutData[];
}

export const setLayoutAction = ({ layout, clientViewUri }: SetLayoutActionParams) => ({
  type: 'SET_LAYOUT' as SelectLayoutActions,
  payload: { layout, clientViewUri },
});

export const resetLayoutAction = ({ clientViewUri, layout }: ResetLayoutActionPayload) => ({
  type: 'RESET_LAYOUT' as SelectLayoutActions,
  payload: { clientViewUri, layout },
});

export const initLayoutAction = ({ cvc, layouts }: InitActionParams) => ({
  type: 'INIT_LAYOUT' as SelectLayoutActions,
  payload: { cvc, layouts },
});

export interface LayoutActionPayload {
  name: string;
  clientViewUri: string;
  isRenamed?: boolean;
}

export interface LayoutUrisAction {
  clientViewUri: string;
  layoutUri: string;
}

export interface ShareLayoutAction {
  clientViewUri: string;
  layout: GlideLayoutData;
}

export interface SetActionPayload {
  clientViewUri: string;
  layoutUri: string;
}

export interface CreateLayoutActionPayload {
  clientViewUri: string;
  name: string;
  duplicateLayout?: GlideLayoutData;
  layout?: GlideLayoutData;
}

export interface DuplicateLayoutActionPayload {
  clientViewUri: string;
  layout: GlideLayoutData;
}

export interface UpdateActionPayload {
  clientViewUri: string;
  name?: string;
  isRenamed?: boolean;
  layout?: GlideLayoutData;
}

export interface ResetLayoutActionPayload {
  clientViewUri: string;
  layout?: GlideLayoutData;
}

export const createLayoutAction = ({ clientViewUri, name, duplicateLayout }: CreateLayoutActionPayload) => ({
  type: 'CREATE_LAYOUT' as LayoutActions,
  payload: { clientViewUri, name, duplicateLayout },
});

export const duplicateLayoutAction = ({ clientViewUri, layout }: DuplicateLayoutActionPayload) => ({
  type: 'CREATE_LAYOUT' as LayoutActions,
  payload: { clientViewUri, layout },
});

export const updateLayoutAction = ({ clientViewUri, name, isRenamed, layout }: UpdateActionPayload) => ({
  type: 'UPDATE_LAYOUT' as LayoutActions,
  payload: { clientViewUri, name, isRenamed, layout },
});

export const deleteLayoutAction = ({ clientViewUri, layoutUri }: LayoutUrisAction) => ({
  type: 'DELETE_LAYOUT' as LayoutActions,
  payload: { clientViewUri, layoutUri },
});

export const shareLayoutAction = ({ clientViewUri, layoutUri }: LayoutUrisAction) => ({
  type: 'SHARE_LAYOUT' as LayoutActions,
  payload: { layoutUri, clientViewUri },
});

function checkDuplicateLayout(clientViewData: any, name: string | undefined) {
  const isDuplicate = clientViewData.webLayouts?.find(
    (layout: { data: { name: string } }) => layout.data.name?.toLowerCase() === name?.toLowerCase(),
  );

  if (isDuplicate) {
    return {
      type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
      payload: { errorMessage: 'Layout name already exists' },
    };
  }

  return false;
}

export function* createLayoutSaga(action: {
  payload: CreateLayoutActionPayload;
}): Generator<SelectEffect | PutEffect, void, ClientViewConfigurationData & DisplayViewGroup> {
  const { name, clientViewUri, layout: duplicateLayout } = action.payload;
  const clientViewConfiguration = yield select(selectCVCUri, clientViewUri);
  const newLayout: GlideLayoutDataPayload = {
    name,
    // @ts-ignore
    json_layout:
      // @ts-ignore
      window['gridInstances'][clientViewUri].current.instance.NAME === 'dxPivotGrid'
        ? // @ts-ignore
          window['gridInstances'][clientViewUri].current.instance.getDataSource().state()
        : // @ts-ignore
          window['gridInstances'][clientViewUri].current.instance.state(),
  };

  if (duplicateLayout) {
    newLayout['name'] = `${duplicateLayout.data.name}-copy`;
  }

  let newLayoutAPIResponse: any = {
    data: {
      name: '',
      owner: '',
      json_layout: {},
    },
    uri: '',
    date: '',
  };

  const transform = (body: string) => ({
    views: {
      [clientViewConfiguration.uri]: { webLayouts: JSON.parse(body) },
    },
  });

  const clientViewData = yield select(selectClientViewData, clientViewConfiguration.uri);
  const duplicateLayoutAction = checkDuplicateLayout(clientViewData, name);

  if (duplicateLayoutAction) {
    yield put(duplicateLayoutAction);
  } else {
    yield putResolve(
      mutateAsync(
        glideQuery({
          endpoint: `/glide/views/layouts`,
          body: {
            display_view_uri: clientViewConfiguration.view,
            web_layout_data: JSON.stringify(newLayout.json_layout),
            web_layout_name: newLayout.name,
            client_view_type: clientViewConfiguration.client_view_type,
          },
          options: { method: 'POST' },
          update: {
            views: (prev: any, next: any) => {
              /**
               * Identifying the newly created layout using lodash differenceBy (https://lodash.com/docs/4.17.15#differenceBy) method
               * by comparing two arrays (newLayoutArray, oldLayoutArray) on unique property uri
               */
              newLayoutAPIResponse = differenceBy(
                next[clientViewConfiguration.uri].webLayouts as GlideLayoutData[],
                clientViewData.webLayouts,
                'uri',
              )?.[0];

              return {
                ...prev,
                [clientViewConfiguration.uri]: {
                  ...prev[clientViewConfiguration.uri],
                  webLayouts: next[clientViewConfiguration.uri].webLayouts,
                },
              };
            },
          },
          transform,
          meta: {
            notification: {
              [actionTypes.MUTATE_START]: 'Creating layout...',
              [actionTypes.MUTATE_SUCCESS]: 'Layout created successfully',
            },
          },
        }),
      ),
    );

    saveLayoutNameLocally({ clientViewConfiguration, layoutUri: newLayoutAPIResponse!.uri });
    /**
     * using non-null assertion operator (!) to fix TS null/undefined error
     * Once the layout is created newLayoutAPIResponse will always hold some value.
     */
    yield put(toggleComponentViewDisplayAction('columnManagerInspector', action.payload.clientViewUri, false));
    yield put(
      updateComponentViewAction('gridLayout', clientViewConfiguration.uri, {
        selectedLayout: newLayoutAPIResponse!,
        permissions: yield select(selectLayoutPermissions, newLayoutAPIResponse!),
        hasChanges: false,
      }),
    );
    // @ts-ignore: FIXME
    if (window['gridInstances'][clientViewConfiguration.uri].current.instance.NAME === 'dxPivotGrid') {
      // @ts-ignore: FIXME
      window['gridInstances'][clientViewConfiguration.uri].current.instance
        .getDataSource()
        .state(newLayoutAPIResponse!.data.json_layout);
    } else {
      // @ts-ignore: FIXME
      window['gridInstances'][clientViewConfiguration.uri].current.instance.state(
        newLayoutAPIResponse!.data.json_layout,
      );
    }
  }
}

export function* updateLayoutSaga(action: {
  payload: UpdateActionPayload;
}): Generator<SelectEffect | PutEffect, void, ClientViewConfigurationData & ComponentGridLayout> {
  const { clientViewUri, isRenamed, name, layout: layoutToUpdate } = action.payload;
  const clientViewConfiguration: ClientViewConfigurationData = yield select(
    selectCVCUri,
    action.payload?.clientViewUri,
  );
  const viewGridLayout: ComponentGridLayout = yield select(selectViewComponent, 'gridLayout', clientViewUri);
  if (!viewGridLayout?.selectedLayout) return;
  if (isRenamed) {
    const clientViewData = yield select(selectClientViewData, clientViewConfiguration.uri);
    const duplicateLayoutAction = checkDuplicateLayout(clientViewData, name);
    if (duplicateLayoutAction) {
      yield put(duplicateLayoutAction);
      return;
    }
    saveLayoutNameLocally({ clientViewConfiguration, layoutUri: viewGridLayout.selectedLayout!.uri });
  }

  const gridState =
    // @ts-ignore
    window['gridInstances'][clientViewUri].current.instance.NAME === 'dxPivotGrid'
      ? // @ts-ignore
        window['gridInstances'][clientViewUri].current.instance.getDataSource().state()
      : // @ts-ignore
        window['gridInstances'][clientViewUri].current.instance.state();

  yield putResolve(
    mutateAsync(
      glideQuery({
        endpoint: `/glide/views/layouts`,
        body: {
          web_layout_uri: layoutToUpdate?.uri,
          // @ts-ignore
          web_layout_data: JSON.stringify(gridState),
          web_layout_name: name,
        },
        options: {
          method: 'POST',
          // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
          headers: { 'X-Http-Method-Override': 'PUT' },
        },
        update: {
          views: (prevData: any) => ({
            ...prevData,
            [clientViewConfiguration.uri]: {
              ...prevData[clientViewConfiguration.uri],
              webLayouts: prevData[clientViewConfiguration.uri].webLayouts.map((layout: GlideLayoutData) => {
                if (layout.uri === layoutToUpdate?.uri) {
                  return {
                    ...layout,
                    data: {
                      ...layout?.data,
                      name: name,
                      json_layout: {
                        ...gridState,
                      },
                    },
                  };
                }
                return layout;
              }),
            },
          }),
        },
        meta: {
          notification: {
            [actionTypes.MUTATE_START]: 'Updating layout...',
            [actionTypes.MUTATE_SUCCESS]: 'Layout updated successfully',
          },
        },
      }),
    ),
  );
  //Column-manager is closed when we discard the column changes.
  yield put(toggleComponentViewDisplayAction('columnManagerInspector', action.payload.clientViewUri, false));
  if (!isRenamed) {
    yield put(
      updateComponentViewAction('gridLayout', clientViewConfiguration.uri, {
        selectedLayout: {
          ...layoutToUpdate,
          data: {
            ...layoutToUpdate?.data,
            json_layout: {
              ...gridState,
            },
          },
        },
      }),
    );
  }
}

export function* deleteLayoutSaga(action: {
  payload: LayoutUrisAction;
}): Generator<SelectEffect | PutEffect, void, ClientViewConfigurationData> {
  const { layoutUri, clientViewUri } = action.payload;
  const clientViewConfiguration = yield select(selectCVCUri, clientViewUri);
  const viewGridLayout = yield select(selectViewComponent, 'gridLayout', clientViewUri);
  yield putResolve(
    mutateAsync(
      glideQuery({
        endpoint: `/glide/views/layouts`,
        body: {
          web_layout_uri: layoutUri,
          display_view_uri: clientViewConfiguration.view,
          client_view_type: clientViewConfiguration.client_view_type,
        },
        // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
        options: { method: 'POST', headers: { 'X-Http-Method-Override': 'DELETE' } },
        update: {
          views: (prevData: any) => ({
            ...prevData,
            [clientViewConfiguration.uri]: {
              ...prevData[clientViewConfiguration.uri],
              webLayouts: prevData[clientViewConfiguration.uri].webLayouts.filter(
                (layout: GlideLayoutData) => layout.uri !== layoutUri,
              ),
            },
          }),
        },
        meta: {
          notification: {
            [actionTypes.MUTATE_START]: 'Deleting layout...',
            [actionTypes.MUTATE_SUCCESS]: 'Layout deleted successfully',
          },
        },
      }),
    ),
  );
  deleteLayoutLocally(clientViewConfiguration);
  if (viewGridLayout?.selectedLayout?.uri === layoutUri) {
    yield put(
      updateComponentViewAction('gridLayout', clientViewConfiguration.uri, {
        selectedLayout: null,
      }),
    );
  }
}

export function* shareLayoutSaga(action: {
  payload: LayoutUrisAction;
}): Generator<SelectEffect | PutEffect, void, ClientViewConfigurationData & ComponentGridLayout> {
  const { layoutUri, clientViewUri } = action.payload;
  const clientViewConfiguration: ClientViewConfigurationData = yield select(selectCVCUri, clientViewUri);
  const viewGridLayout: ComponentGridLayout = yield select(selectViewComponent, 'gridLayout', clientViewUri);

  // set the owner of layout to null value
  yield putResolve(
    mutateAsync(
      glideQuery({
        endpoint: `/glide/layout/share`,
        body: {
          web_layout_uri: viewGridLayout.selectedLayout!.uri,
        },
        options: {
          method: 'POST',
          // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
          headers: { 'X-Http-Method-Override': 'PUT' },
        },
        update: {
          views: (prevData: any) => ({
            ...prevData,
            [clientViewConfiguration.uri]: {
              ...prevData[clientViewConfiguration.uri],
              // eslint-disable-next-line no-confusing-arrow
              webLayouts: prevData[clientViewConfiguration.uri].webLayouts.map((layout: GlideLayoutData) =>
                layoutUri === layout.uri
                  ? {
                      ...layout,
                      data: {
                        ...layout.data,
                        permissions: [
                          'instance/permissions/readonly',
                          'instance/permissions/allow_client_admin',
                          'instance/permissions/allow_administrator',
                          'instance/permissions/byowner',
                        ],
                      },
                    }
                  : layout,
              ),
            },
          }),
        },
        meta: {
          notification: {
            [actionTypes.MUTATE_START]: 'Sharing layout...',
            [actionTypes.MUTATE_SUCCESS]: 'The layout is now shared with all users',
          },
        },
      }),
    ),
  );
}

export const getSelectedLayoutKey = (cvc: ClientViewConfigurationData) =>
  `selected-layout-${cvc.uri}-${cvc.client_view_type}`;

export const getLastGridStateKey = (cvc: ClientViewConfigurationData) =>
  `last-state-${cvc.uri}-${cvc.client_view_type}`;

/**
 * Restore last selected layout or latest created one
 */
export function* initLayoutSaga(action: { payload: InitActionParams }) {
  const { cvc, layouts } = action.payload;
  const storedSelectedLayoutName = localStorage.getItem(getSelectedLayoutKey(cvc));
  const clientViewData: View = yield select(selectClientViewData, cvc.uri);

  // Last selected layout
  if (storedSelectedLayoutName && layouts.length > 0) {
    const selectedLayout = clientViewData.webLayouts.find(layout => layout.uri === storedSelectedLayoutName);
    if (selectedLayout) {
      yield put(setLayoutAction({ layout: selectedLayout, clientViewUri: selectedLayout.uri }));
    }
  } else {
    const defaultLayout = clientViewData.webLayouts.find(layout => layout.data.is_default);
    if (defaultLayout) {
      yield put(setLayoutAction({ layout: defaultLayout, clientViewUri: cvc.uri }));
    }
  }
}

export const selectLayoutPermissions = (state: RootState, layout: GlideLayoutData): WebLayoutPermission | undefined => {
  const glideSession: GlideSession = state.auth.glideSession;
  const layoutPermissions = layout.data?.permissions;
  const isAdminUser = glideSession.user.roles.includes('administrator');
  const isClientAdminUser = glideSession.user.roles.includes('client_admin');
  const isUserOwnedLayout =
    layoutPermissions?.includes('instance/permissions/byowner') &&
    layout.data?.owner.includes(glideSession?.username as string);
  const hasAdminRights = isAdminUser && layoutPermissions?.includes('instance/permissions/allow_administrator');
  const hasClientAdminRights =
    isClientAdminUser && layoutPermissions?.includes('instance/permissions/allow_client_admin');
  const isMutable = isUserOwnedLayout || hasAdminRights || hasClientAdminRights;

  return {
    isAdminUser,
    isClientAdminUser,
    isUserOwnedLayout,
    hasClientAdminRights,
    isMutable,
  };
};

export function* resetLayoutSaga(action: {
  payload: ResetLayoutActionPayload;
}): Generator<SelectEffect | PutEffect, void, ClientViewConfigurationData & ComponentGridLayout> {
  const { clientViewUri, layout } = action.payload;
  //Column-manager is closed when we discard the layout changes.
  yield put(toggleComponentViewDisplayAction('columnManagerInspector', action.payload.clientViewUri, false));
  yield put(
    updateComponentViewAction('gridLayout', clientViewUri, {
      selectedLayout: layout,
      hasChanges: false,
    }),
  );

  // @ts-ignore: FIXME
  if (window['gridInstances'][clientViewUri].current.instance.NAME === 'dxPivotGrid') {
    // @ts-ignore: FIXME
    window['gridInstances'][clientViewUri].current.instance.getDataSource().state(layout?.data?.json_layout || null);
  } else {
    // @ts-ignore: FIXME
    window['gridInstances'][clientViewUri].current.instance.state(layout?.data?.json_layout || null);
  }
}

export function* setLayoutSaga(action: { payload: SetLayoutActionParams }): any {
  const { layout, clientViewUri } = action.payload;
  yield put(toggleComponentViewDisplayAction('columnManagerInspector', action.payload.clientViewUri, false));
  yield put(updateComponentViewAction('viewInspector', clientViewUri, { isCollapsed: true }));
  yield put(
    updateComponentViewAction('gridLayout', clientViewUri, {
      permissions: yield select(selectLayoutPermissions, layout),
      selectedLayout: layout,
      hasChanges: false,
    }),
  );
  yield call(onInspectorRelease);

  // @ts-ignore: FIXME
  if (window['gridInstances'][clientViewUri].current.instance.NAME === 'dxPivotGrid') {
    // @ts-ignore: FIXME
    window['gridInstances'][clientViewUri].current.instance.getDataSource().state(layout.data.json_layout);
  } else {
    console.info(`[layout.saga] apply layout "${layout.data.name}" (${layout.data.owner}) `);
    // @ts-ignore: FIXME
    window['gridInstances'][clientViewUri].current.instance.state(layout.data.json_layout);
  }
}

export function* watchSetLayout() {
  yield takeLatest<any>('SET_LAYOUT' as SelectLayoutActions, setLayoutSaga);
}

export function* watchResetLayouts() {
  yield takeLatest<any>('RESET_LAYOUT' as SelectLayoutActions, resetLayoutSaga);
}

export function* watchInitLayouts() {
  yield takeLatest<any>('INIT_LAYOUT' as SelectLayoutActions, initLayoutSaga);
}

export function* watchCreateLayouts() {
  yield takeLatest<any>('CREATE_LAYOUT' as SelectLayoutActions, createLayoutSaga);
}

export function* watchUpdateLayouts() {
  yield takeLatest<any>('UPDATE_LAYOUT' as SelectLayoutActions, updateLayoutSaga);
}

export function* watchDeleteLayouts() {
  yield takeLatest<any>('DELETE_LAYOUT' as SelectLayoutActions, deleteLayoutSaga);
}

export function* watchShareLayouts() {
  yield takeLatest<any>('SHARE_LAYOUT' as SelectLayoutActions, shareLayoutSaga);
}

export default [
  watchCreateLayouts,
  watchUpdateLayouts,
  watchDeleteLayouts,
  watchShareLayouts,
  watchSetLayout,
  watchInitLayouts,
  watchResetLayouts,
];
