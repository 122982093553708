import { RootState } from 'src/reducers/rootReducer';

export interface IPortfolioState {
  selectedFundURIs: any;
}

export const initialState = (): IPortfolioState => ({
  selectedFundURIs: {},
});

export enum PortfolioActionTypes {
  STORE_SELECTED_PORTFOLIOS = 'STORE_SELECTED_PORTFOLIOS',
  RESET_STORED_PORTFOLIO = 'RESET_STORED_PORTFOLIO',
  SET_PORTFOLIOS = 'SET_PORTFOLIOS',
}

export const portfolios = (
  state: IPortfolioState = initialState(),
  action: { type: PortfolioActionTypes; payload?: any },
) => {
  switch (action.type) {
    case PortfolioActionTypes.SET_PORTFOLIOS:
      return {
        ...state,
        selectedFundURIs: {
          ...state.selectedFundURIs,
          [action.payload.clientViewUri.lastSplitValue()]: JSON.parse(
            localStorage.getItem(action.payload.clientViewUri.lastSplitValue())!,
          ) || [{ uri: 'EMPTY_GRID' }],
        },
      };
    case PortfolioActionTypes.STORE_SELECTED_PORTFOLIOS:
      localStorage.setItem(
        `${action.payload.portfolio_type.lastSplitValue()}`,
        JSON.stringify(action.payload.selectedFundURIs),
      );
      return {
        ...state,
        selectedFundURIs: {
          ...state.selectedFundURIs,
          [action.payload.portfolio_type]: action.payload.selectedFundURIs,
        },
      };
    case PortfolioActionTypes.RESET_STORED_PORTFOLIO:
      return {
        ...state,
        selectedFundURIs: {
          ...state.selectedFundURIs,
          [action.payload.clientViewUri.lastSplitValue()]: JSON.parse(
            localStorage.getItem(action.payload.clientViewUri.lastSplitValue())!,
          ) || [{ uri: 'EMPTY_GRID' }],
        },
      };
    default:
      return state;
  }
};

export const selectedFundURIs = (state: RootState, clientViewConfiguration: any) =>
  state.portfolios.selectedFundURIs[clientViewConfiguration?.uri?.lastSplitValue()]?.map(
    (item: { uri: string; display_name: any }) => item.uri,
  );

export const selectedFunds = (state: RootState, clientViewConfiguration: any) =>
  state.portfolios.selectedFundURIs[clientViewConfiguration?.uri?.lastSplitValue()];
